import * as _jarallax2 from "./src/jarallax.esm";

var _jarallax = "default" in _jarallax2 ? _jarallax2.default : _jarallax2;

import * as _jarallaxVideo2 from "./src/jarallax-video.esm";

var _jarallaxVideo = "default" in _jarallaxVideo2 ? _jarallaxVideo2.default : _jarallaxVideo2;

import * as _jarallaxElement2 from "./src/jarallax-element.esm";

var _jarallaxElement = "default" in _jarallaxElement2 ? _jarallaxElement2.default : _jarallaxElement2;

var exports = {};
const jarallax = _jarallax.default;
const jarallaxVideo = _jarallaxVideo.default;
const jarallaxElement = _jarallaxElement.default;
exports = {
  jarallax,

  jarallaxElement() {
    return jarallaxElement(jarallax);
  },

  jarallaxVideo() {
    return jarallaxVideo(jarallax);
  }

};
export default exports;
const _jarallax3 = exports.jarallax,
      _jarallaxElement3 = exports.jarallaxElement;
export { _jarallax3 as jarallax, _jarallaxElement3 as jarallaxElement };